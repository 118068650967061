











































































import { Component, Vue } from 'vue-property-decorator';
import CustomDialogMenu from '../../ui/CustomDialogMenu.vue';
import HeaderT9n from './header.t9n';
import { $user } from '@/services/user.service';
import { MenuState } from '@/interfaces/common.interface';
import { $modalService } from '@/services/custom-modal.service';
import { IChangePasswordModal, ICustomModalConfig, IUploadPhotoModal } from '@/interfaces/custom-modal.interface';
import { $webApi } from '@/services/web-api.service';
import { SettingsMsg } from '@/utilities/translation.enum';
import { settingsModule } from '@/store/modules/settings.module';

interface IState {
    mode: MenuState;
}


@Component({
    components: { CustomDialogMenu }
})
export default class Header extends Vue {
    [x: string]: any;
    breakpointWidth:number = 1151;
    disabledBtn:boolean = false;
    State: IState = {
        mode: 'extended'
    };


    T9n = new HeaderT9n();

    goToHomeScreen() {
        this.$router.push({ name: 'dashboard' });
    }

    beforeMount() {
        this.$eventBus.$on("innerWidthChanged", (data: number) => {
            this.State.mode = data < this.breakpointWidth ? "collapsed" : "extended";
            this.disabledBtn = data < this.breakpointWidth ? true : false;
        });
    }
    async logout(): Promise<void> {
        if (this.$route.path !== 'login') {
            this.$router.push({ name: 'login' });
            await $user.logout();
        }
    }

    onToggle(): void {
        this.State.mode = this.State.mode === 'collapsed' ? 'extended' : 'collapsed';

        this.$parent.$emit('leftMenuEvent');
    }

    changePassword(): void {
        const modalConfig: IChangePasswordModal = {
            template: 'ChangePasswordModal',
            data: { user: this.T9n.getUser, isLogin: false },
            dynamic: {
                resolve: this.onChangePassword.bind(this)
            }
        };
        $modalService.show(modalConfig);
    }

    private async onChangePassword(data: any) {
        try {
            const response = await $webApi.PUT.ChangePassword.Update(data, () => {
                this.changePassword();
            });
            $modalService.showSuccess();
            setTimeout(()=> { this.logout() }, 1000);
        } catch(error: any) {
            const isValidationError = error.response.status === 400;
            if (!isValidationError) {
                throw error;
            }
        }
    }

    uploadPhoto(): void {
        const modalConfig: IUploadPhotoModal = {
            template: 'UploadPhotoModal',
            data: {id: this.T9n.getUser?.id, picture: this.picture },
            dynamic: {
                resolve: this.onUpdatePhoto.bind(this)
            }
        };
        $modalService.show(modalConfig);
    }

    private async onUpdatePhoto(data: any) {
        settingsModule.setPicture(data.picture);
        settingsModule.setPictureLocalStorage(data.picture);
        const response = await $webApi.PUT.UpdatePhoto.Update(data);
        $modalService.showSuccess();
    }

    changeLanguage() {
        const modalConfig: ICustomModalConfig = {
            template: 'ChangeLanguageModal',
            title: this.T9n.languages,
            dynamic: {}
        };
        $modalService.show(modalConfig);
    }

    get picture(): string | null {
        return this.T9n.Picture;
    }

    get UploadPicture() : string {
        return this.$T(SettingsMsg.UPDATE_PHOTO).value;
    }
    get Language() : string {
        return this.$T(SettingsMsg.LANGUAGE).value;
    }
    get ChangePassword() : string {
        return this.$T(SettingsMsg.CHANGE_PASSWORD).value;
    }

}
